import day from 'dayjs'

const expense = {
    name: '',
    amount: '',
    type: "",
    payFreqPerMonth: "",
    dueDate: new day().toISOString()
}

export default expense;