<template>
	<v-container fluid id="expense-container">
		<v-row>
			<v-col>
				<div class="text-overline text-center white--text">Expense(s)</div>
			</v-col>
		</v-row>

		<transition name="bounce" enter-active-class="bounceInUp" leave-active-class="bounceOutDown">
			<v-row v-show="expand">
				<v-col cols="12" sm>
					<v-card
						class="mx-auto"
						outlined
						:color="filteredExpenses.length > 0 ? 'pink lighten-5' : 'transparent'"
						max-width="444"
						max-height="475"
						min-height="475"
						elevation="15"
						v-show="expand"
						v-bind:style="{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }"
					>
						<div v-if="!fetchingExpenseData">
							<donut-chart :items="filteredExpenses" v-if="filteredExpenses.length > 0"></donut-chart>
							<div v-else class="expense-emoji">😲</div>
						</div>

						<v-progress-circular
							indeterminate
							size="75"
							width="10"
							color="white"
							v-show="fetchingExpenseData"
						></v-progress-circular>
						<div
							class="text-center text-overline"
							v-bind:style="{ color: filteredExpenses.length > 0 ? 'black' : 'white' }"
						>
							Monthly - ${{ numberFormat(sumOfFiltered(filteredExpenses)) }}
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm>
					<v-card class="mx-auto" outlined color="pink accent-1" max-width="644" elevation="10">
						<v-toolbar color="pink accent-1">
							<div class="expense-list-toolbar">
								<v-text-field
									label="Search Expense"
									hide-details
									prepend-icon="mdi-magnify"
									single-line
									filled
									color="purple darken-4"
									v-model="searchExpense"
								>
								</v-text-field>
								<v-btn fab color="pink darken-1" dark small @click="dialog = true">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</div>
						</v-toolbar>
					</v-card>
					<v-card
						class="mx-auto expense-list"
						outlined
						color="pink lighten-4"
						max-width="644"
						max-height="400"
						min-height="400"
						elevation="10"
					>
						<v-list
							subheader
							three-line
							rounded
							dark
							v-bind:style="{ height: filteredExpenses.length < 4 ? '400px' : '' }"
						>
							<v-subheader inset>
								<v-icon>mdi-clipboard-list</v-icon>
							</v-subheader>

							<v-list-item-group color="pink lighten-4">
								<v-list-item
									v-for="expense in filteredExpenses"
									:key="expense.name"
									@click="editItem(expense)"
									v-bind:style="{ backgroundColor: searchExpense.length <= 0 ? 'black' : '#37474F' }"
								>
									<v-list-item-avatar>
										<v-icon v-bind:style="{ backgroundColor: expense.backgroundColor, color: 'black' }">
											mdi-cash-minus
										</v-icon>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>{{ expense.name }} </v-list-item-title>
										<v-list-item-subtitle>${{ numberFormat(expense.amount) }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<div v-if="filteredExpenses.length === 0">No Results Found.</div>
							</v-list-item-group>
						</v-list>
					</v-card>
				</v-col>
				<v-dialog v-model="dialog" max-width="500px">
					<v-card class="add-edit-expense-modal" dark>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>
						<template>
							<validation-observer ref="observer" v-slot="{ invalid }">
								<form @submit.prevent="handleSubmit">
									<v-container>
										<v-row>
											<v-col cols="12" sm="6" md="4">
												<validation-provider v-slot="{ errors }" name="name" rules="required|alpha_spaces">
													<v-text-field
														v-model="editedItem.name"
														label="Name"
														:error-messages="errors"
														required
													></v-text-field>
												</validation-provider>
											</v-col>
											<v-col cols="12" sm="6" md="4">
												<validation-provider
													v-slot="{ errors }"
													name="amount"
													:rules="{
														regex: /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/,
													}"
												>
													<v-text-field
														v-model="editedItem.amount"
														label="Amount($)"
														type="Number"
														inputmode="decimal"
														:error-messages="errors"
														step="any"
														required
														@change="computedAmount"
													></v-text-field>
												</validation-provider>
											</v-col>

											<v-col cols="12" sm="6" md="4">
												<validation-provider v-slot="{ errors }" name="type" rules="required">
													<v-select
														:items="items"
														label="Select Type"
														select="editedItem.type"
														v-model="editedItem.type"
														@change="setType(editedItem.type)"
														:errors-messages="errors"
														required
													>
													</v-select>
												</validation-provider>
											</v-col>
											<v-menu
												ref="menu1"
												v-model="menu1"
												:close-on-content-click="false"
												transition="scale-transition"
												offset-y
												max-width="290px"
												min-width="auto"
											>
												<template v-slot:activator="{ on, attrs }">
													<validation-provider v-slot="{ errors }" rules="required" name="date">
														<v-text-field
															v-model="computedDateFormatted"
															label="Date"
															hint="MM/DD/YYYY format"
															persistent-hint
															prepend-icon="mdi-calendar"
															v-bind="attrs"
															:error-messages="errors"
															@blur="editedItem.dueDate = parseDate(dateFormatted)"
															v-on="on"
														></v-text-field>
													</validation-provider>
												</template>
												<v-date-picker v-model="editedItem.dueDate" no-title @input="menu1 = false"></v-date-picker>
											</v-menu>
											<v-col cols="12" sm="6" md="4" v-if="editedItem.expenseId">
												<div class="text-overline">
													Next Due Date - {{ nextDueDate(editedItem.dueDate, editedItem.type) }}
												</div>
											</v-col>
										</v-row>
									</v-container>

									<v-card-actions>
										<v-row>
											<v-btn
												fab
												color="pink darken-3"
												dark
												x-small
												@click="deleteItem(editedItem)"
												v-if="editedItem.expenseId"
											>
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
											<v-spacer></v-spacer>
											<v-btn color="purple" text @click="close"> Cancel </v-btn>
											<v-btn color="purple" text type="submit" :disabled="invalid"> Save </v-btn>
										</v-row>
									</v-card-actions>
								</form>
							</validation-observer>
						</template>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="deep-purple" text @click="closeDelete">Cancel</v-btn>
							<v-btn color="deep-purple" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</transition>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import helperNumberFormat from "../helpers/numberFormat.js";
import { required, regex, alpha_spaces } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import dayjs from "dayjs";
import expense from "../models/expense";
import DonutChart from "../components/DonutChart.vue";

setInteractionMode("eager");

extend("required", {
	...required,
	message: "{_field_} can not be empty",
});

extend("alpha_spaces", {
	...alpha_spaces,
	message: "Only letters and spaces allowed",
});

extend("regex", {
	...regex,
	message: "Wrong format",
});

export default {
	name: "Expenses",
	components: {
		DonutChart,
		ValidationObserver,
		ValidationProvider,
	},
	data: (vm) => ({
		expand: false,
		searchExpense: "",
		dialog: false,
		dialogDelete: false,
		activeItem: {},
		expenses: [],
		totals: [],
		editedIndex: -1,
		editedItem: {
			...expense,
		},
		defaultItem: {
			...expense,
		},
		items: ["monthly", "biweekly", "weekly", "daily"],
		menu1: false,
		dateFormatted: vm.formatDate(
			new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
		),
	}),

	computed: {
		...mapGetters([
			"getExpenses",
			"getTotalMonthlyExp",
			"getTotalBiweeklyExp",
			"getTotalWeeklyExp",
			"getTotalDailyExp",
			"getGrandTotalExp",
		]),

		fetchingExpenseData() {
			return this.$store.state.fetchingData;
		},
		computedDateFormatted() {
			return this.formatDate(this.editedItem.dueDate);
		},
		formTitle() {
			return this.editedIndex === -1 ? "New Expense" : "Edit Expense";
		},
		calendarFormat() {
			return (this.editedItem.dueDate = this.changeDate(this.editedItem.dueDate));
		},
		computedAmount() {
			return (this.editedItem.amount = Number(this.editedItem.amount));
		},
		filteredExpenses() {
			return this.getExpenses.filter((expense) => {
				return expense.name.toLowerCase().indexOf(this.searchExpense.toLowerCase()) > -1;
			});
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	mounted() {
		this.expand = true;
	},
	methods: {
		sumOfFiltered(array) {
			return array.reduce((acc, obj) => {
				return acc + obj.amount * obj.payFreqPerMonth;
			}, 0);
		},
		formatDate(date) {
			if (!date) return null;
			return dayjs(date).format("MM/DD/YYYY");
		},
		parseDate(date) {
			// Has something to do with setting the start date
			if (!date) return null;
			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
		setType(type) {
			if (type === "monthly") return (this.editedItem.payFreqPerMonth = 1);
			if (type === "biweekly") return (this.editedItem.payFreqPerMonth = 2);
			if (type === "weekly") return (this.editedItem.payFreqPerMonth = 4);
			if (type === "daily") return (this.editedItem.payFreqPerMonth = 30);
		},
		numberFormat(number) {
			return helperNumberFormat(number);
		},
		changeDate(data) {
			let thisMonth = dayjs().format("MM");
			let date = dayjs(data).format("DD");
			let year = dayjs().format("YYYY");
			let combined = `${thisMonth} ${date}, ${year}`;
			return dayjs(combined).toISOString();
		},

		nextDueDate(data, type) {
			let now = dayjs().unix();
			let thisMonth = dayjs().format("MMMM");
			let date = dayjs(data).format("DD");
			let year = dayjs().format("YYYY");
			let combined = `${thisMonth} ${date}, ${year}`;
			let past = now > dayjs(combined).unix();

			const properFormat = (number, combined) => {
				//refactor this to keep adding until date is reached
				return dayjs(combined).add(number, "days").format("MMMM DD, YYYY");
			};
			if (type == "daily" && past) return properFormat(1);
			if (type == "weekly" && past) return properFormat(7, combined);
			if (type == "biweekly" && past) return properFormat(14, combined);
			if (type == "monthly" && past) return properFormat(30, combined);
			return combined;
		},

		editItem(item) {
			this.editedIndex = this.filteredExpenses.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.filteredExpenses.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.filteredExpenses.splice(this.editedIndex, 1);
			if (this.editedItem.expenseId) {
				this.$store.dispatch("deleteUserExpense", this.editedItem).then(() => {
					this.getExpenseTotals();
				});
			}
			this.closeDelete();
			this.close();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.$refs.observer.reset();
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		getExpenseTotals() {
			this.$store
				.dispatch("getUserExpenses")
				.then(() => {
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async handleSubmit() {
			const isValid = await this.$refs.observer.validate();
			if (!isValid) {
				return;
			}
			if (this.editedItem.name && this.editedItem.amount && this.editedItem.type && this.editedItem.dueDate) {
				this.save();
			}
		},

		save() {
			if (this.editedIndex > -1) {
				Object.assign(this.filteredExpenses[this.editedIndex], this.editedItem);
				this.$store
					.dispatch("updateUserExpense", this.editedItem)
					.then(() => {
						this.getExpenseTotals();
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.filteredExpenses.push(this.editedItem);
				this.$store
					.dispatch("addUserExpense", this.editedItem)
					.then(() => {
						this.getExpenseTotals();
					})
					.catch((error) => {
						console.log(error);
					});
			}
			this.close();
		},
	},
};
</script>

<style lang="scss">
@import "src/styles/colors.scss";
#expense-container {
	background-image: linear-gradient(to bottom, #151430, #3a2455);
	height: 110%;
}
.expense-emoji {
	font-size: 10pc;
}
.add-edit-expense-modal {
	padding: 5%;
}
.expense-list-toolbar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}
.expense-list {
	overflow-x: hidden;
	overflow-y: scroll;
	margin-top: 1vh;
}
.expense-table {
	border: 1px solid $moneyo-blue !important;
	background-color: $indigo-darken-4 !important;
	.v-data-footer {
		// background-color: blue !important;
	}
}
</style>
